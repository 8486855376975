<template>
  <div class="nav-tree-item-group" :class="{'disabled': floderThis.moveIds.includes(menuItemData.id) }">
    <div class="nav-tree-item-name ellipsis" :style="{ 'padding-left': num*15+'px'}" :class="menuItemData.id === selectId?'active':''" @click="menuCheck(menuItemData)">
      <span v-if="menuItemData.has_child === 0 || (!menuItemData.kbId && menuItemData.children.length < 1)" class="opt-btn">
        <span class="zw"></span>
      </span>
      <span class="opt-btn" v-else-if="!isOpen" @click.stop="toggleTree(menuItemData)">
        <svg-icon icon-class="open-btn" style="width:0.8em;height:0.8em"></svg-icon>
      </span>
      <span class="opt-btn" v-else-if="isOpen" @click.stop="toggleTree(menuItemData)">
        <svg-icon icon-class="close-btn" style="width:0.8em;height:0.8em"></svg-icon>
      </span>
      <span class="sub-tree-item ellipsis" :class="menuItemData.id === selectId ? 'active' : ''">
        <span class="opt-btn">
          <svg-icon v-if="!menuItemData.kbId && !menuItemData.hasArticle" icon-class="folder_icon" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="!menuItemData.type" icon-class="document" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newExcel'" icon-class="excel-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newPowerPoint'" icon-class="ppt-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newMp4'" icon-class="mp4-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else-if="menuItemData.type==='newDoc'" icon-class="doc-wiki" class="word-icon-span"></svg-icon>
          <svg-icon v-else icon-class="document" class="word-icon-span"></svg-icon>
        </span>
        <span class="ellipsis title">{{ menuItemData.name }}</span>
      </span>
    </div>
    <ul class="nav-tree-item-subTree" v-show="isOpen" v-if="menuItemData.children && menuItemData.children.length > 0">
      <li v-for="(item, index) in menuItemData.children" :key="index">
        <menuItem :menuItemData="item" :kbId="kbId" :selectId="selectId" :num="num + 1"  @menuCheck="menuCheck"/>
      </li>
    </ul>
  </div>
</template>

<script>
import { getWikiChildData } from "@/api/wikiApi.js"

export default {
  name: 'menuItem',
  props: {
    kbId: {
      type: Number,
      defalut: null
    },
    selectId: {
      type: Number,
      defalut: null
    },
    menuItemData: {
      type: Object,
      default: () => {}
    },
    num: {
      type: Number,
      default: 0
    }
  },
  inject: ['floderThis'],
  data() {
    return {
      isOpen: false,
      href: ''
    }
  },
  watch: {
    kbId(val) {
      this.isOpen = false
    }
  },
  methods: {
    menuCheck(item) {
      this.$emit('menuCheck', item)
    },
    toggleTree(item) {
      // 判断是否有子节点-有且无数据则请求接口
      if (item.has_child === 1 &&(!item.children || item.children.length === 0)) {
        getWikiChildData({
          parentId: item.id,
        }).then(res => {
          if (res.code === '000000') {
            item.children = res.data
            this.isOpen = !this.isOpen
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.isOpen = !this.isOpen
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-tree-item-group {
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6; /* 可选：降低透明度以表示禁用状态 */
    cursor: not-allowed; /* 可选：更改光标样式为不可用 */
  }
  .nav-tree-item-name {
    line-height: 30px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 3px;
    border-radius: 2px;
    border: 2px solid rgba(0,0,0,0);
    width: 460px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    .opt-btn {
      display: inline-block;
      font-size: 16px;
      line-height: 0;
      text-align: center;
      .zw {
        display: flex;
        height: 100%;
        width: 0.8em;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
      }
    }
    .opt-btn+.sub-tree-item {
      margin-left: 3px;
    }
    .word-icon-span {
      width:1em !important;
      height:1em !important
    }
    .sub-tree-item {
      display: flex;
      align-items: center;
      height: 100%;
      width: calc(100% - 20px);
      line-height: 32px;
      &.active {
        color: #f01d94;
        font-weight: 700;
      }
    }
    .title {
      display: block;
      padding-left: 5px;
      font-size: 14px;
      user-select: none;
    }
    &:hover {
      background: #eeeeee;
      .add-icon {
        display: inline-block;
        padding-right: 10px;
      }
    }
    .add-icon {
      display: none;
    }
    &.active {
      background: #e0e9ff;
    }
  }
  .nav-tree-item-subTree{
    list-style-type: none;
    padding-left:0;
  }
  // .nav-tree-item-subTree > li{
  //   margin-bottom:0.2rem;
  //   margin-left:5px;
  // }
  .nav-tree-item-name{
    margin-left: 5px;
    position: relative;
  }
}
</style>
