<template>
<!-- 我的空间和文件目录公用显示组件 -->
  <div class="folder-panel" :class="{'paddingBottom': checkedLen>0}"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-panel">
      <div class="path" :class="{'more': catalogTree.length===1}">
        <span v-for="(item,index) in catalogTree" :key="index" class="clearfix">
          <span class="fl">{{item}}</span>
          <svg-icon class="fl" icon-class="right" style="margin:2px 5px;font-size:16px;" v-if="index<catalogTree.length-1"></svg-icon>
        </span>
      </div>
      <div class="right-btn" v-if="hasBtn">
        <right-btn :type="type" :encryptionStr="encryptionStr" v-if="permission === 1 || type === 'my'" @updateChapterList="getChapterList"></right-btn>
      </div>
    </div>
    <div class="list-main" v-if="listData.length>0">
      <template v-if="type === 'my'">
        <span class="checkbox" @click="checkAllSelected" v-if="hasSelect && listData.length>0">
          <span class="checkbox-inner" :class="checkedLen===listData.length?'allcheck':(checkedLen===0?'' : 'somecheck')"></span>
        </span>
      </template>
      <div class="flexJK">
        <span class="file-name">名称</span>
        <span class="gd"></span>
        <span class="file-owner">所有者</span>
        <span class="gd"></span>
        <span class="file-updatetime">修改时间</span>
        <span class="operate-btn">
          <svg-icon icon-class="list-view" v-if="nowType==='card'"></svg-icon>
          <svg-icon icon-class="card-view" v-if="nowType==='list'"></svg-icon>
        </span>
      </div>
    </div>
    <ul class="list-container" v-if="listData.length>0">
      <li class="list-main" v-for="(item, index) in listData" :key="item.id">
        <template v-if="type === 'my'">
          <span class="checkbox" @click="checkSelected(item, index)" v-if="hasSelect && listData.length>0">
            <span class="checkbox-inner" :class="item.checked?'allcheck':''"></span>
          </span>
        </template>
        <!-- 目录则打开下一级，文章则到文章展示页 -->
        <a @click="forDetail(item)" style="display:flex;flex: 1 1 0%; flex-direction: row">
          <span class="file-name">
            <span class="icon">
              <template v-if="item.hasArticle === 1">
                <svg-icon v-if="!item.type" icon-class="markdown_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newExcel'" icon-class="excel_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newPowerPoint'" icon-class="ppt"></svg-icon>
                <svg-icon v-else-if="item.type==='newMp4'" icon-class="mp4"></svg-icon>
                <svg-icon v-else-if="item.type==='newDoc'" icon-class="word"></svg-icon>
                <svg-icon v-else icon-class="document"></svg-icon>
              </template>
              <svg-icon v-else icon-class="folder_icon"></svg-icon>
            </span>
            <span class="name" :title="item.name">{{item.name}}</span>
            <span :class="item.isCollect ? 'collect-img-yes' : 'collect-img'" @click.stop="collectClick(item)">
              <el-tooltip effect="dark" :content="item.isCollect ? '取消收藏' : '收藏'" placement="top-start">
                <svg-icon v-if="item.isCollect" icon-class="collect_yellow"></svg-icon>
                <svg-icon v-else icon-class="collect_white"></svg-icon>
              </el-tooltip>
            </span>
          </span>
          <span class="gd"></span>
          <span class="file-owner">{{item.username}}</span>
          <span class="file-updatetime">{{item.updateTime ? item.updateTime : item.createTime}}</span>
        </a>
        <span class="operate-btn" v-if="type !== 'share'">
          <right-operate :treeData="item" :type="type" @addFolderSuccess="addFolderSuccess" @deleteSuccess="deleteSuccess"></right-operate>
        </span>
      </li>
    </ul>
    <div v-if="!permission && permission === 0" class="noPower-div">
      <div class="noPower-box">
        <svg-icon icon-class="noPermission"></svg-icon>
        <div class="title-text">没有权限访问</div>
        <p class="tip-text">你可以向<span class="owner-text">{{ owner}}(所有者)</span>申请权限</p>
      </div>
    </div>
    <div v-else-if="listData.length===0" class="noData">
      <img src="../../../public/static/img/noData.png" alt="">
      <p v-if="type==='my'">这里空空如也，快去添加内容吧！</p>
      <p v-else>这是一个空的文件夹！</p>
    </div>
    <div class="bottomFixed" v-show="checkedLen>0">
      <div class="list-main">
        <span class="checkbox" @click="checkAllSelected" v-if="hasSelect">
          <span class="checkbox-inner" style="margin-top:8px;" :class="checkedLen===listData.length?'allcheck':(checkedLen===0?'':'somecheck')"></span>
        </span>
        <el-button @click="deleteBatch" type="danger" size="small" plain style="height:32px" :disabled="type !== 'my' && checkedLen < 1">删除</el-button>
        <el-button v-if="type === 'my'" @click="handleMove" type="primary" size="small" plain style="height:32px" :disabled="checkedLen < 1">移动到</el-button>
      </div>
    </div>
    <!-- 移动目录弹窗 -->
    <move-dialog :visible="moveVisible" :id="moveIds" :spaceFoldList="spaceFoldList" @closeMove="closeMove" @moveSuccess="moveSuccess"></move-dialog>
  </div>
</template>
<script>
import rightOperate from '@/components/rightOperate'
import rightBtn from './rightBtn.vue'
import { selectChapterByParentId, softDeleteSpace, getParentTreeList, getHavePermission } from '@/api/space'
import { newFile } from '@/api/fileApi'
import { collectSpace } from '@/api/collect'
import { viewLatelyList } from '@/api/template'
import moveDialog from '@/components/moveDialog/index'
import { getMoveSpaceList } from '@/api/space.js'
import { handleTreeData } from '@/utils/index'

export default {
  components: {
    rightOperate,
    rightBtn,
    moveDialog
  },
  name: 'folderView',
  data() {
    return {
      nowType: 'list',
      listData: [],
      checkedLen: 0,
      isLoading: false,
      isNeddGetTree: false,
      setting: {
        title: '',
        keywords: '',
        description: ''
      },
      permission: null,
      owner: '',
      moveVisible: false,
      moveIds: [],
      spaceFoldList: []
    }
  },
  provide() {
    return {
      floderThis: this,
      // rightMoveHandle: this.rightMoveHandle,
      // moveIds : this.moveIds
    }
  },
  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: 'keywords', content: this.setting.keywords },
        { name: 'description', content: this.setting.description }
      ]
    }
  },
  props: {
    encryptionStr: {
      type: String,
      default: ''
    },
    // 是否有头部又上角的按钮
    hasBtn: {
      type: Boolean,
      default: true
    },
    // 是否有CheckBox
    hasSelect: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    // 刷新页面时，初始填充
    catalogTree() {
      console.log(this.$route.path)
      const folder = []
      if (this.$store.getters.catalogTree.length === 0) {
        if (this.$route.path === '/drive/me') {
          folder.push('我的空间')
        }
        if (this.$route.path === '/drive/shared') {
          folder.push('与我分享')
        }
        return folder
      } else {
        return this.$store.getters.catalogTree
      }
    }
  },
  watch: {
    encryptionStr: {
      handler(val) {
        if (val) {
          if (val !== '-1') {
            this.getPermission()
          } else {
            this.getChapterList()
          }
          if (this.isNeddGetTree) {
            this.getParentTree()
          }
        }
      },
      // deep: true,
      immediate: true
    },
    catalogTree: {
      handler(val) {
        // 刷新数据取sessionStorage的数据
        if (val.length < 1) {
          this.isNeddGetTree = true
        } else {
          this.isNeddGetTree = false
        }
        this.setting.title = val[val.length - 1]
        this.setting.description = val > 0 ? val.join('>') : ''
      },
      immediate: true
    }
  },
  mounted() {
    this.$EventBus.$on('getListEvent', ($event) => {
      this.getChapterList()
    })
    getMoveSpaceList().then(res => {
      this.spaceFoldList = res.data && res.data.length > 0 ? handleTreeData(res.data) : []
    }).catch(error => { console.log(error) })
  },
  methods: {
    getPermission() {
      this.isLoading = true
      getHavePermission({
        dpIdStr: this.$store.getters.udId.replaceAll('-', ','),
        encryptionStr: this.encryptionStr
      }).then((res) => {
        this.permission = res.data.permission
        this.owner = res.data.username
        if (this.permission && this.permission !== 0) {
          this.getChapterList()
        } else {
          this.isLoading = false
        }
      }).catch(() => {
        this.isLoading = false
      })
    },
    getChapterList() {
      // 这个接口也要返回节点信息，当前的encryptionStr是文件夹的串，-1的话就是最顶层（我的空间或与我分享（这个没有新增））
      this.isLoading = true
      this.checkedLen = 0
      selectChapterByParentId({
        encryptionStr: this.encryptionStr,
        region: this.type !== 'sharedme' ? 'my' : this.type
      }).then((res) => {
        if (res.code === '000000' && res.data) {
          this.listData = res.data
        } else {
          this.listData = []
        }
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    getParentTree() {
      console.log('接口获取父级树-----', this.encryptionStr)
      getParentTreeList({ encryptionStr: this.encryptionStr }).then((res) => {
        this.isNeddGetTree = false
        if (res.code === '000000' && res.data) {
          const nameList = res.data.map(item => item.name)
          nameList.unshift(this.type === 'sharedme' ? '与我分享' : this.type === 'my' ? '我的空间' : '与我协作')
          this.$store.dispatch('setCatalogTree', nameList)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkSelected(item, index) {
      this.$nextTick(() => {
        // this.listData[index].checked = !this.listData[index].checked
        item.checked = !item.checked
        this.checkedLen = this.listData.filter(i => i.checked).length
        console.log(item)
      })
    },
    checkAllSelected() {
      const len = this.listData.filter(i => i.checked).length
      const allLen = this.listData.length
      if (len === allLen) {
        this.checkedLen = 0
        this.listData.forEach(i => { i.checked = false })
      } else {
        this.checkedLen = this.listData.length
        this.listData.forEach(i => { i.checked = true })
      }
    },
    addFolderSuccess() {
      this.getChapterList()
    },
    forDetail(item) {
      const params = {
        chapterId: item.id,
        type: item.type,
        username: item.username,
        workcode: item.workcode
      }
      // 是否是上传的文件
      if (item.type) {
        viewLatelyList(params).then(res => {
          console.log(res)
        }).catch(error => console.log(error))
        const Base64 = require('js-base64').Base64
        const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
        if (item.type === 'newMp4' || item.type === 'other') {
          this.isLoading = true
          newFile({
            action: 'getFileDetail',
            code: urlCode,
            path: item.dpath
          }).then((res) => {
            this.isLoading = false
            if (item.type === 'other') {
              window.open(res.data.url, '_blank')
            } else {
              // 在新路由页面打开视频播放
              const { href } = this.$router.resolve({
                path: `/wiki/player?name=${res.data.detail.name}&url=${res.data.url}`
              })
              window.open(href, '_blank')
            }
          }).catch(err => {
            this.isLoading = false
            console.log(err)
          })
        } else {
          let href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + item.dpath
          if (this.type !== 'sharedme') {
            // 我的空间 直接跳转编辑
            href = href + `&code=${urlCode}`
            window.open(href, '_blank')
          } else {
            // 与我分享 需要判断权限
            getHavePermission({
              dpIdStr: this.$store.getters.udId.replaceAll('-', ','),
              encryptionStr: item.encryptionStr
            }).then((res) => {
              if (res.data.permission && [1, 2].includes(res.data.permission)) {
                href = href + `&code=${urlCode}`
              }
              console.log(9999, href)
              window.open(href, '_blank')
            }).catch(() => {
            })
          }
        }
        return
      }
      // Markdown文件
      if (item.hasArticle === 0) {
        // 文件夹
        if (this.type !== 'sharedme') {
          // 我的空间点击
          this.$router.push({
            // path: item.router + '/' + item.fileName
            path: '/drive/folder/' + item.encryptionStr
          })
        } else {
          // 与我分享
          this.$router.push({
            // path: item.router + '/' + item.fileName
            path: '/drive/share/folder/' + item.encryptionStr
          })
        }
        this.$store.dispatch('setCatalogTree', [...this.catalogTree, item.name])
      } else {
        const { href } = this.$router.resolve({ path: '/wiki/article/' + item.encryptionStr })
        window.open(href, '_blank')
      }
    },
    deleteSuccess() {
      this.getChapterList()
    },
    deleteBatch() {
      this.$confirm('是否要删除节点或文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const idList = this.listData.filter(item => item.checked).map(item => item.id)
          softDeleteSpace({ idList: idList }).then(response => {
            if (response.code === '000000') {
              this.$message.success(response.message)
              this.getChapterList()
            } else {
              this.$message.error(response.message)
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除操作'
          })
        })
    },
    collectClick(item) {
      collectSpace({
        chapterId: item.id,
        username: this.$store.getters.name
      }).then(response => {
        if (response.code === '000000') {
          this.$message.success(response.message)
          item.isCollect = item.isCollect ? null : item.id
          // this.getChapterList()
        } else {
          this.$message.error(response.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleMove() {
      this.moveIds = this.listData.filter(item => item.checked).map(item => item.id)
      this.moveVisible = true
    },
    closeMove() {
      this.moveVisible = false
    },
    moveSuccess() {
      this.moveVisible = false
      this.getChapterList()
    },
    rightMoveHandle(id) {
      this.moveIds = [id]
      this.moveVisible = true
    }
  },
  destroyed() {
    this.$EventBus.$off('getListEvent')
  }
}
</script>
<style lang="scss" scoped>
.folder-panel {
  position: relative;
  font-size: 14px;
  color: #808183;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.paddingBottom {
    padding-bottom: 40px;
  }
  .top-panel {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    padding-bottom: 10px;
    .path {
      font-size: 18px;
      color: #38383a;
      display: flex;
      &.more {
        font-weight: bold;
      }
    }
    .right-btn {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
}

.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 45px;
      margin-right: 10px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.list-main {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d8dadb;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #f4f6f7;
    .collect-img {
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }
  .collect-img {
    display: none;
  }
  .collect-img-yes {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
  .checkbox {
    width: 24px;
    display: flex;
    flex: 0 0 auto;
  }
  .gd {
    width: 10px;
  }
  .file-name {
    display: flex;
    flex-grow: 8;
    flex-shrink: 1;
    flex-basis: 100px;
    padding-left: 0;
    .icon {
      display: block;
      width: 30px;
      height: 30px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
        margin-top: 12px;
      }
    }
    .name {
      display: flex;
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
      max-width: 500px;
    }
  }
  .file-owner {
    display: flex;
    flex-grow: 6;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .file-updatetime {
    display: flex;
    flex-grow: 4;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .operate-btn {
    display: flex;
    flex: 0 0 auto;
    padding: 0 12px;
    .svg-icon {
      width: 1.2em;
      height: 1.2em;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}
.list-container {
  flex: auto;
  height: calc(100vh - 170px);
  overflow-y: auto;
  .list-main {
    height: 50px;
    line-height: 50px;
    a {
      color: #808183;
    }
    &:hover {
      a {
        color: #409eff;
      }
    }
  }
  .checkbox-inner {
    margin-top: 15px;
  }
  .operate-btn {
    cursor: pointer;
    span {
      padding-top: 5px;
    }
    .svg-icon {
      margin-top: 16px !important;
    }
  }
}
.bottomFixed {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: calc(100% - 260px);
  .list-main {
    height: 50px;
    border-top: 1px solid #d8dadb;
    border-bottom: 0 none;
    padding: 8px 0;
  }
}
.checkbox-inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-top: 12px;
  background-color: #fff;
  z-index: 1;
  transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
  &:hover {
    border-color: #409eff;
    cursor: pointer;
  }
  &::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
  }
  // 全选
  &.allcheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      transform: rotate(45deg) scaleY(1);
    }
  }
  // 未全选
  &.somecheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: #fff;
      height: 2px;
      width: 12px;
      transform: scale(.5);
      left: 0;
      right: 0;
      top: 5px;
    }
  }
}
.flexJK {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
}
.noData {
  text-align: center;
  padding-top: 150px;
}
</style>
