<template>
  <div v-if="visible">
    <el-dialog
      :visible="true"
      title="移动到"
      v-ismove
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="closeTab">
      <div class="move-dialog">
        <!-- 知识库列表 -->
        <div class="wiki-list">
          <div class="wiki-item" :class="!kbId ? 'light-wiki-item' : ''" @click="changeCollection({ id: null })">
            <svg-icon icon-class="folder_icon"></svg-icon>
            <span class="name-text">我的空间</span>
          </div>
          <!-- <template v-if="floderThis && floderThis.moveIds.length < 2"> -->
            <el-divider></el-divider>
            <div class="wiki-title-tip">知识库</div>
            <div class="wiki-item" :class="item.id === kbId ? 'light-wiki-item' : ''" v-for="item in knowledgeList" :key="item.id"  @click="changeCollection(item)">
              <svg-icon icon-class="light-wiki"></svg-icon>
              <span class="name-text">{{ item.name }}</span>
            </div>
          <!-- </template> -->
        </div>
        <div class="wiki-tree"
          v-loading="isLoading">
          <!-- <div v-if="!kbId" class="no-data">
            <img src="../../../public/static/img/noData.png" alt="">
            <p>请先选择左侧知识库或我的空间</p>
          </div> -->
          <div v-if="!isLoading && wikiData.length < 1" class="no-data">
            <img src="../../../public/static/img/noData.png" alt="">
            <p>{{ !kbId ? '空间暂无可选文件夹' : '该知识库暂无相关文档！' }}</p>
          </div>
          <!-- <el-empty v-if="wikiData.length < 1" description="暂无数据" /> -->
          <ul v-else>
            <li v-for="(item, index) in wikiData" :key="index">
              <menuItem :menuItemData="item" :kbId="kbId" :selectId="selectId" @menuCheck="menuCheck"/>
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTab">取 消</el-button>
        <el-button :loading="submitLoading" :disabled="!selectId" type="primary" @click="submitUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { moveToBase, getWikiChildData } from '@/api/wikiApi.js'
import { moveToSpace } from '@/api/space.js'
import { getEditKnowledgeSpace } from '@/api/knowledgePermissions'
// import { handleTreeData } from '@/utils/index'
import menuItem from './menuItem.vue'

export default {
  components: {
    menuItem
  },
  data() {
    return {
      isLoading: false,
      submitLoading: false,
      // filterText: '',
      // defaultProps: {
      //   children: 'children',
      //   label: 'name'
      // },
      knowledgeList: [],
      wikiData: [],
      kbId: null,
      selectId: null
    }
  },
  inject: ['floderThis'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // id: {
    //   type: Array,
    //   default: () => []
    // },
    spaceFoldList: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    visible: {
      handler(newVal) {
        this.kbId = null
        if (newVal && this.spaceFoldList) {
          // 我的空间数据处理
          this.wikiData = this.spaceFoldList
        }
        if (newVal && this.knowledgeList.length < 1) {
          this.getKnowledgeList()
        }
      },
      deep: true
    },
    // filterText(val) {
    //   this.$refs.tree.filter(val);
    // }
  },
  methods: {
    menuCheck(item) {
      console.log('menuCheck', item)
      this.selectId = item.id
    },
    closeTab() {
      this.$emit('closeMove')
      this.selectId = null
      this.kbId = null
    },
    changeCollection(kbItem) {
      this.kbId = kbItem.id
      this.selectId = null
      if(!kbItem.id) {
        this.wikiData = this.spaceFoldList
      } else {
        // 选中知识库获取第一层级的相关数据
        this.getTechnologyMenuFetch()
      }
    },
    // filterNode(value, data) {
    //   if (!value) return true;
    //   return data.name.indexOf(value) !== -1
    // },
    getKnowledgeList() {
      const params = {
        dpIdStr: this.$store.getters.udId.replaceAll('-', ','),
        encryptionStr: this.encryptionStr
      }
      console.log('this.$store.getters', this.$store.getters, params)
      getEditKnowledgeSpace(params).then(res => {
        this.knowledgeList = res.data && res.data.length > 0 ? res.data : []
      }).catch(error => { console.log(error) })
    },
    getTechnologyMenuFetch() {
      this.isLoading = true
      getWikiChildData({
        kbId: this.kbId
      }).then(res => {
        this.isLoading = false
        this.wikiData = res.data.length > 0 ? res.data : []
      }).catch(() => this.isLoading = false)
    },
    submitUpdate() {
      if (!this.kbId) {
        this.moveToSpaceFetch()
      } else {
        this.moveToWikiFetch()
      }
    },
    moveToSpaceFetch() {
      this.submitLoading = true
      const params = {
        parentId: this.selectId,
        id: this.floderThis.moveIds
      }
      moveToSpace(params).then((res) => {
        this.submitLoading = false
        if (res.code === '000000') {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 1400
          })
          this.$emit('moveSuccess')
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        }
      }).catch(err => {
        this.submitLoading = false
        console.log(err)
      })
    },
    moveToWikiFetch() {
      this.submitLoading = true
      const params = {
        kbId: this.kbId,
        parentId: this.selectId,
        id: this.floderThis.moveIds
      }
      moveToBase(params).then((res) => {
        this.submitLoading = false
        if (res.code === '000000') {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 1400
          })
          this.selectId = null
          this.kbId = null
          this.$emit('moveSuccess')
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        }
      }).catch(err => {
        this.submitLoading = false
        console.log(err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.move-dialog {
  display: flex;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  height: 350px;
  // overflow: hidden;
  align-items: stretch;
  .wiki-list {
    width: 240px;
    border-right: 1px solid #e9e9e9;
    padding: 10px;
    overflow-y: auto;
    .wiki-item {
      padding: 5px;
      line-height: 30px;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      align-items: center;
      border-radius: 6px;
      &:hover {
        background-color: #eeeeee;
      }
      .name-text {
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .light-wiki-item {
      background-color: #e0e9ff !important;
      font-weight: 700;
    }
  }
  .wiki-tree {
    padding: 5px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  .wiki-title-tip {
    color: #61666f;
    line-height: 30px;
    margin-bottom: 2px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
  /deep/ .el-divider--horizontal {
    margin: 8px 0;
  }
}
</style>